/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as turf from '@turf/turf';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import { SessionStorage } from 'sarsaparilla';
import SimpleMap from 'shared-ui/maps/templates/SimpleMap';
import { convertSearchResultToGeojson } from 'shared-ui/maps/helpers/parseFeatures';
import { getMapBoundingBox, updateFacilityIcons } from 'shared-ui/maps/helpers/common';

import { fetchBoundary } from 'ui-search/src/actions/search';
import { BOUNDARY_TYPES } from 'ui-search/src/constants';

import {
    mapID,
    searchResultZoomLevel,
    defaultZoomLevel,
} from '../../../constants/mapValues';

function HomepageMapContainer(props) {
    const dispatch = useDispatch();

    const [geojson, setGeojson] = useState(turf.featureCollection([]));
    const [selectedFeatureId, setSelectedFeatureId] = useState(null);
    const [location, setLocation] = useState({});
    const [center, setCenter] = useState([-98.6, 39.8]);
    const [zoom, setZoom] = useState(defaultZoomLevel);
    const [boundaryType, setBoundaryType] = useState(null);
    const [boundaryName, setBoundaryName] = useState(null);
    const [boundaryData, setBoundaryData] = useState(null);
    const [radiusCenter, setRadiusCenter] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const mapRef = useRef();

    const getCurrentZoom = () => {
        if (!mapRef.current) return null;
        return mapRef.current.getZoom();
    };

    const onShowListToggle = () => {
        props.setShouldBeFullWidth(false);
        props.setShowNavSidebar(true);
    };

    const configRef = useRef({
        cluster: false,
        shouldAlwaysFitData: false,
        closePopupOnMove: true,
        mapSpecialControls: {
            toggleFunc: onShowListToggle,
            legendContent: <ul />,
            getCurrentZoom,
            maxZoom: 9,
            maxZoomNearbyRadius: 6,
        },
        shouldFitBoundary: true,
    });
    const isMapDragged = useRef(false);
    const specialMapControls = useRef();

    const boundaryResults = useSelector((state) =>
        get(state, 'navigation.searchResults.boundaryResults', [])
    );
    const selectedResult = useSelector((state) =>
        get(state, 'navigation.map.mapSearchResult', {})
    );

    const fetchBoundarySearchResults = (type, name) =>
        dispatch(fetchBoundary(type, name));

    useEffect(() => {
        const savedLocation = SessionStorage?.getItem('savedLocation');
        if (!props.ignoreSessionStore && savedLocation)
            setLocation(JSON.parse(savedLocation));
    }, []);

    useEffect(() => {
        const convertedInventory = convertSearchResultToGeojson(props.mapInventory);
        setGeojson(convertedInventory);
    }, [props.mapInventory]);

    const updateGeojson = (featureId) => {
        const updatedData = updateFacilityIcons(featureId, geojson);
        setGeojson(updatedData);
    };

    useEffect(() => {
        let featureId;
        if (props.selectedCardItem) {
            const parsedFeature = convertSearchResultToGeojson([props.selectedCardItem])
                .features[0];
            featureId = parsedFeature?.properties?.featureId;
        }
        setSelectedFeatureId(featureId);

        updateGeojson(featureId);
    }, [props.selectedCardItem]);

    useEffect(() => {
        const { useNavSidebar, showNavSidebar, width } = props;
        if (!specialMapControls.current?.showList || !useNavSidebar) return;

        if (showNavSidebar) specialMapControls.current?.showList.hide();
        else if (width > 790) specialMapControls.current?.showList.show();
    }, [props.showNavSidebar]);

    useEffect(() => {
        if (!specialMapControls.current?.showList) return;
        const { showNavSidebar, width } = props;

        if (width > 790 && !showNavSidebar) specialMapControls.current?.showList.show();
        else specialMapControls.current?.showList.hide();
    }, [props.width]);

    useEffect(() => {
        if (props.shouldBeFullWidth && mapRef.current) mapRef.current.resize();
    }, [props.shouldBeFullWidth]);

    useEffect(() => {
        if (!isEqual(location, props.mapSearchResultLocation))
            setLocation(props.mapSearchResultLocation);
    }, [props.mapSearchResultLocation]);

    useEffect(() => {
        if (boundaryResults.length) return;
        const { latitude, longitude, zoomLevel } = location;
        if (zoomLevel && !isEqual(zoomLevel, zoom)) setZoom(zoomLevel);

        if (!longitude || !latitude) return;
        const locationCenter = [longitude, latitude];
        if (!isEqual(center, locationCenter)) setCenter(locationCenter);

        if (!mapRef.current || isMapDragged.current || !searchTerm) {
            isMapDragged.current = false;
            return;
        }

        mapRef.current.flyTo({
            center: locationCenter,
            zoom: searchResultZoomLevel,
        });
    }, [boundaryResults, location]);

    const updateSearchSession = () => {
        if (!mapRef.current) return;
        let savedLocation = SessionStorage?.getItem('savedLocation');
        if (savedLocation) savedLocation = JSON.parse(savedLocation);

        let mapSearchTerm = props.selectedMapSearchTerm || '';
        if (!props.ignoreSessionStore && savedLocation?.searchTerm)
            mapSearchTerm = savedLocation.searchTerm;

        const mapCenter = mapRef.current.getCenter();
        const zoomLevel = mapRef.current.getZoom();

        const locationParams = {
            longitude: mapCenter.lng,
            latitude: mapCenter.lat,
            zoomLevel,
            searchTerm: mapSearchTerm,
        };

        try {
            if (!props.ignoreSessionStore)
                SessionStorage.setItem('savedLocation', JSON.stringify(locationParams));
        } catch (e) {
            // absorbing this error. This should be very unlikely since SessionStorage has fallbacks
            // eslint-disable-next-line no-console
            console.warn(e);
        }
        specialMapControls.current.legend?.updateGlobalContent({
            maxZoom: 9,
            currentZoom: getCurrentZoom(),
        });
    };

    const removeBoundaries = () => {
        setBoundaryData(null);
        setBoundaryName(null);
        setBoundaryType(null);
    };

    const getRadiusCenterPoint = (feature) => {
        if (!feature) return;
        const hasCoords = feature.lat && feature.lng;
        if (!hasCoords) return;

        const centerPoint = turf.point([feature.lng, feature.lat]);
        if (!isEqual(centerPoint, radiusCenter)) setRadiusCenter(centerPoint);
    };

    useEffect(() => {
        getRadiusCenterPoint(selectedResult);
        updateSearchSession();
        const term = props.selectedMapSearchTerm;
        if (!term) {
            removeBoundaries();
            if (radiusCenter) setRadiusCenter(null);
            setSearchTerm(null);
            return;
        }

        const locationComponents = term.split(',');
        const locationSize = locationComponents.length;

        const isState = locationSize === 1;
        const isFacility = selectedResult?.type === 'Rec Area';

        let type = isState ? BOUNDARY_TYPES.state : null;
        if (isFacility) type = BOUNDARY_TYPES.facility;

        const name = isState || isFacility ? locationComponents[0] : null;

        if (!isEqual(type, boundaryType)) setBoundaryType(type);
        if (!isEqual(name, boundaryName)) setBoundaryName(name);

        setSearchTerm({
            isState,
            isFacility,
            type,
            name,
        });
    }, [props.selectedMapSearchTerm, selectedResult]);

    useEffect(() => {
        fetchBoundarySearchResults(boundaryType, boundaryName);
    }, [boundaryType, boundaryName]);

    useEffect(() => {
        if (boundaryData && !boundaryResults?.length) {
            setBoundaryData(null);
            return;
        }

        const boundaryResult = boundaryResults[0];
        const boundaryGeojson = boundaryResult?.geo_json;
        if (!boundaryGeojson) return;
        const boundaryFeature = turf.feature(boundaryGeojson, {
            name: boundaryName,
            type: boundaryType,
        });
        if (!isEqual(boundaryData, boundaryFeature)) setBoundaryData(boundaryFeature);
    }, [boundaryResults]);

    const getInventoryData = () => {
        if (!mapRef.current) return;
        const mapBounds = getMapBoundingBox(mapRef.current);

        let geoSearchArgs = {
            lat_sw: mapBounds.minLat,
            lng_sw: mapBounds.minLng,
            lat_ne: mapBounds.maxLat,
            lng_ne: mapBounds.maxLng,
        };

        if (boundaryData) {
            const boundaryBbox = turf.bbox(boundaryData);
            const [lng_sw, lat_sw, lng_ne, lat_ne] = boundaryBbox;
            geoSearchArgs = { lat_sw, lng_sw, lat_ne, lng_ne };
        }

        // removing tour and timedentry_tour
        geoSearchArgs['fq'] = '-entity_type:(tour OR timedentry_tour)';

        props.fetchMapInventory(geoSearchArgs);
        updateSearchSession();
    };

    const debounceGetInventoryData = debounce(getInventoryData, 500);

    const updateInventory = (inventoryProps = {}) => {
        if (inventoryProps.isMapDragged) isMapDragged.current = true;
        removeBoundaries();
        debounceGetInventoryData();
    };

    return (
        <div id={mapID} className="map">
            <SimpleMap
                geojson={geojson}
                boundary={boundaryData}
                radiusCenter={radiusCenter}
                config={configRef.current}
                mapContainer={mapID}
                center={center}
                zoom={zoom}
                service="navigation"
                selectedFeatureId={selectedFeatureId}
                selectedEvent={updateGeojson}
                initialEvent={debounceGetInventoryData}
                onZoomEnd={debounceGetInventoryData}
                onDragEnd={updateInventory}
                specialMapControls={specialMapControls}
                mapRef={mapRef}
                searchTerm={searchTerm}
            />
        </div>
    );
}

HomepageMapContainer.propTypes = {
    mapInventory: PropTypes.array,
    mapSearchResultLocation: PropTypes.object,
    selectedMapSearchTerm: PropTypes.string,
    fetchMapInventory: PropTypes.func,
    ignoreSessionStore: PropTypes.bool,
    selectedCardItem: PropTypes.object,
    showNavSidebar: PropTypes.bool,
    setShowNavSidebar: PropTypes.func,
    useNavSidebar: PropTypes.bool,
    setShouldBeFullWidth: PropTypes.func,
    shouldBeFullWidth: PropTypes.bool,
    width: PropTypes.number,
};

export default HomepageMapContainer;
