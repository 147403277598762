/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

/*
 * AboutUsExperiences.jsx
 * Created by Destin Frasier on 09/20/18
 */

import * as React from 'react';
import {
    Heading,
    ContentContainerBodyShift,
    FlexRow,
    FlexCol,
    BrandingIcons,
} from 'sarsaparilla';

const experiences = [
    {
        icon: <BrandingIcons.Camping aria-label={null} />,
        title: 'Camping',
        shortName: 'camping',
    },
    {
        icon: <BrandingIcons.Picnicking aria-label={null} />,
        title: 'Picnicking',
        shortName: 'picnicking',
    },
    {
        icon: <BrandingIcons.Hiking aria-label={null} />,
        title: 'Hiking',
        shortName: 'hiking',
    },
    {
        icon: <BrandingIcons.Rafting aria-label={null} />,
        title: 'Rafting',
        shortName: 'rafting',
    },
    {
        icon: <BrandingIcons.Ranger aria-label={null} />,
        title: 'Ranger-Led Experiences',
        shortName: 'experiences',
    },
    {
        icon: <BrandingIcons.HistoricTour aria-label={null} />,
        title: 'Historic & Cultural Tours',
        shortName: 'tours',
    },
    {
        icon: <BrandingIcons.RV aria-label={null} />,
        title: 'RVing',
        shortName: 'rv-ing',
    },
    {
        icon: <BrandingIcons.Binoculars aria-label={null} />,
        title: 'Wildlife Viewing',
        shortName: 'wildlife',
    },
    {
        icon: <BrandingIcons.Events aria-label={null} />,
        title: 'Events & Programs',
        shortName: 'events',
    },
    {
        icon: <BrandingIcons.Hunting aria-label={null} />,
        title: 'Hunting & Fishing',
        shortName: 'hunting',
    },
    {
        icon: <BrandingIcons.Biking aria-label={null} />,
        title: 'Biking',
        shortName: 'biking',
    },
    {
        icon: <BrandingIcons.Lodging aria-label={null} />,
        title: 'Unique Lodging',
        shortName: 'lodging',
    },
    {
        icon: <BrandingIcons.Riding aria-label={null} />,
        title: 'Recreational Riding',
        shortName: 'riding',
    },
    {
        icon: <BrandingIcons.HorsebackRiding aria-label={null} />,
        title: 'Horseback Riding',
        shortName: 'horseback',
    },
    {
        icon: <BrandingIcons.Boating aria-label={null} />,
        title: 'Boating',
        shortName: 'boating',
    },
];

export default function AboutUsExperiences() {
    return (
        <ContentContainerBodyShift className="r1s-section-experiences">
            <Heading hasUnderline="center" headingLevel={2} appearance="h4">
                Experiences & Activities
            </Heading>
            <FlexRow>
                <FlexCol lgOffset={1} lg={10}>
                    <p className="r1s-sub-text">
                        The term recreation is about as broad as the great outdoors
                        itself. To better clarify some of the activities available through
                        Recreation.gov, below is a sample of the offerings provided by the
                        14 federal agencies.
                    </p>
                </FlexCol>
            </FlexRow>

            <div className="r1s-media-wrap">
                {experiences.map((experience) => (
                    <div className="r1s-media" key={experience.shortName}>
                        <div className="r1s-media-object">{experience.icon}</div>
                        <div className="r1s-media-description">{experience.title}</div>
                    </div>
                ))}
            </div>

            <div className="r1s-tagline-row">
                <div className="r1s-tagline">
                    And many more experiences to help you{' '}
                    <span className="strong">bring home a story!</span>
                </div>
            </div>
        </ContentContainerBodyShift>
    );
}
