/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { LocalStorage } from 'sarsaparilla';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import includes from 'lodash/includes';
import replace from 'lodash/replace';

import { CAMPGROUND_CAMPSITE_MAP } from '../../shared/constants/campground';

export const CAMPSITES_TAB = 'campsites';
export const SEASONS_TAB = 'seasons';
export const FEES_TAB = 'fees';
export const INFO_TAB = 'info';
export const RATINGS_TAB = 'ratings';
export const LONG_TERM_STAY = 'long_term_stay';
export const LOTTERY_INFO_TAB = 'lottery_info';

export const getLastCampsiteClicked = () => {
    const map = LocalStorage.getItem(CAMPGROUND_CAMPSITE_MAP);

    if (map) {
        const parsed = JSON.parse(map);
        return parsed;
    }

    return null;
};

export const storeCampsiteClicked = (campgroundId, campsiteId) => {
    if (campgroundId && campsiteId) {
        let currentMap = getLastCampsiteClicked(campgroundId);

        if (currentMap) {
            currentMap[campgroundId] = campsiteId;
        } else {
            currentMap = {
                [campgroundId]: campsiteId,
            };
        }
        LocalStorage.setItem(CAMPGROUND_CAMPSITE_MAP, JSON.stringify(currentMap));
    }
};

export const removeCampsiteClicked = (campgroundId) => {
    const currentMap = getLastCampsiteClicked(campgroundId);

    if (!isEmpty(currentMap)) {
        delete currentMap[campgroundId];
    }

    LocalStorage.setItem(CAMPGROUND_CAMPSITE_MAP, JSON.stringify(currentMap));
};

export function tabNameToIndex(tabName, hasLongTermStay = false) {
    switch (tabName) {
        case CAMPSITES_TAB:
            return 0;
        case SEASONS_TAB:
            return hasLongTermStay ? 2 : 1;
        case FEES_TAB:
            return hasLongTermStay ? 3 : 2;
        case INFO_TAB:
            return hasLongTermStay ? 4 : 3;
        case RATINGS_TAB:
            return hasLongTermStay ? 5 : 4;
        case LOTTERY_INFO_TAB:
            return hasLongTermStay ? 6 : 5;
        case LONG_TERM_STAY:
            return 1;
        default:
            return 0;
    }
}

export function tabIndexToName(index, hasLongTermStay = false) {
    switch (index) {
        case 0:
            return CAMPSITES_TAB;
        case 1:
            return hasLongTermStay ? LONG_TERM_STAY : SEASONS_TAB;
        case 2:
            return hasLongTermStay ? SEASONS_TAB : FEES_TAB;
        case 3:
            return hasLongTermStay ? FEES_TAB : INFO_TAB;
        case 4:
            return hasLongTermStay ? INFO_TAB : RATINGS_TAB;
        case 5:
            return hasLongTermStay ? RATINGS_TAB : LOTTERY_INFO_TAB;
        case 6:
            return LOTTERY_INFO_TAB;
        default:
            return CAMPSITES_TAB;
    }
}

export const addReferrer = ({ entityId, source, bookingVersion }) => {
    let refs = LocalStorage.getItem('recommendation-refs');

    if (!refs) {
        refs = {};
    } else {
        refs = JSON.parse(refs);
    }

    const existingReferrer = get(refs, `${entityId}.referrer`, null);
    let referrer = '';

    const campingReferrer = `campground-v${bookingVersion}:${source}`;

    // if referrer for the campground exists...
    if (existingReferrer && !isEmpty(existingReferrer)) {
        // if existing referrer doesn't include campground version info, append it
        if (!includes(existingReferrer, 'campground-v')) {
            referrer = `${existingReferrer}:${campingReferrer}`;
        }
        // if existing referrer does include campground version info, replace the campground version substring
        else {
            referrer = replace(
                existingReferrer,
                /campground-v[0|1]:[a-zA-Z]*/g,
                `${campingReferrer}`
            );
        }
    }
    // if referrer for the campground does NOT exist, initialize it
    else {
        referrer = campingReferrer;
    }

    set(refs, `${entityId}.entityId`, entityId);
    set(refs, `${entityId}.entityType`, 'Campground');
    set(refs, `${entityId}.bookingVersion`, `campground-v${bookingVersion}`);
    set(refs, `${entityId}.bookingSource`, source);
    set(refs, `${entityId}.referrer`, referrer);

    LocalStorage.setItem('recommendation-refs', JSON.stringify(refs));
};
