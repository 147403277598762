/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextArea,
    Button,
    DividerNew as Divider,
    FlexRow,
    FlexCol,
    Label,
    useToast,
    announce,
    LocalStorage,
} from 'sarsaparilla';
import {
    buildSearchNavUri,
    resetSearchFilters,
    updateSearchCriterias,
} from 'ui-search/src/actions/search';
import { getParsedAiSearchCriterias } from 'ui-search/src/utils/aiSearchUtils';

const exampleOptions = [
    'Tent camping in Colorado with a waterfront view.',
    'Tickets to visit the Pearl Harbor National Memorial anytime next month.',
    'Camping with my 30 foot RV electric hookup in Colorado next weekend.',
    'Alaska cabins with any availability in July.',
    'Camping near Washington DC 6 people 2 large tents August 14-16.',
];

export default function HeroAiSearch() {
    const [searchLoading, setSearchLoading] = React.useState(false);
    const [startSearch, setStartSearch] = React.useState(false);
    const [iaTerm, setIaTerm] = React.useState('');
    const [error, setError] = React.useState('');

    const dispatch = useDispatch();
    const searchStore = useSelector((state) => state.search);
    const uiFilters = useSelector((state) => state.search?.uiFilters);
    const showToast = useToast();
    const textAreaRef = React.useRef();

    const navigateToSearch = () => {
        const url = buildSearchNavUri('/search', { search: searchStore });
        window.location.assign(url);
        setTimeout(() => {
            setSearchLoading(false);
            setStartSearch(false);
        }, 0);
    };

    React.useEffect(() => {
        if (startSearch) {
            setSearchLoading(false);
            navigateToSearch();
        }
        // eslint-disable-next-line
    }, [startSearch]);

    const triggerTextAreaError = () => {
        const message = 'Please add a search query';
        setError(message);
        const hasTextAreaRef = textAreaRef?.current?.focus;
        if (hasTextAreaRef) {
            textAreaRef.current.focus();
            announce(message);
        }
    };

    const submitSearch = async (value) => {
        const term = !value ? iaTerm : value;
        if (!term) {
            triggerTextAreaError();
            return;
        }
        setSearchLoading(true);
        try {
            dispatch(resetSearchFilters());
            const { data } = await axios.get(`${process.env.API}/search/parse?q=${term}`);
            const params = getParsedAiSearchCriterias(data, uiFilters);
            dispatch(
                updateSearchCriterias({
                    ...params,
                    ai_query: term,
                    show_ai_search: true,
                })
            );
            setStartSearch(true);
            LocalStorage.setItem('ai-search', term);
        } catch {
            setSearchLoading(false);
            showToast('There was an error, try again later.', {
                type: 'error',
                delay: 5000,
            });
        }
    };

    const onChange = (e) => {
        e.preventDefault();
        setIaTerm(e.target?.value);
        if (error) {
            setError('');
        }
    };

    const onExampleSelect = (v) => {
        setIaTerm(v);
        if (error) {
            setError('');
        }
        submitSearch(v);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            submitSearch();
        }
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();
        submitSearch();
    };

    return (
        <>
            <form
                onSubmit={handleSubmitForm}
                className="nav-search-input-wrapper nav-search-input-area-container "
            >
                <TextArea
                    className="nav-search-input-area"
                    placeholder="Tell us what kind of outdoor recreation you want to reserve. Be as descriptive as possible."
                    id="navigation-ia-area"
                    isLabelVisible={false}
                    onKeyDown={handleKeyDown}
                    value={iaTerm}
                    onChange={onChange}
                    innerRef={textAreaRef}
                    errorText={error}
                    isInvalid={error}
                />
                <Button
                    className="nav-search-button"
                    clickTagCategory="Homepage"
                    clickTagAction={`AI Search - ${iaTerm}`}
                    clickTagLabel="Hero"
                    isLoading={searchLoading}
                    type="submit"
                >
                    SEARCH
                </Button>
            </form>

            <div>
                <Divider className="mt-3 mb-2" />
                <FlexRow
                    className="pb-2"
                    hasGutters={false}
                    justifyContent="space-between"
                >
                    <FlexCol>
                        <Label>Example Searches:</Label>
                    </FlexCol>
                </FlexRow>
                <FlexRow
                    alignItems="stretch"
                    hasGutters={false}
                    className="nav-search-example-boxes"
                >
                    {exampleOptions.map((item) => (
                        <FlexCol>
                            <Button
                                onClick={() => onExampleSelect(item)}
                                isDisabled={searchLoading}
                                size="sm"
                                isUnstyled
                                clickTagCategory="Homepage"
                                clickTagAction={`AI Search - ${item}`}
                                clickTagLabel="Hero"
                            >
                                {item}
                            </Button>
                        </FlexCol>
                    ))}
                </FlexRow>
            </div>
        </>
    );
}
