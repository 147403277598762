/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import fetch from 'isomorphic-fetch';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import find from 'lodash/find';
import lodashSize from 'lodash/size';
import cloneDeep from 'lodash/cloneDeep';
import capitalize from 'lodash/capitalize';
import axios from 'axios';

import { LoggingHelper, SessionStorage } from 'sarsaparilla';

import { cloneState } from '../utils/stateUtils';
import * as types from '../constants';
import * as utils from '../utils/utils';
import { CAMPSITE_ACCESSIBLE_FILTER as accessibilityFilter } from '../constants';
import { parseCampingFilters, parseCampsiteFilters } from '../utils/filters';
import {
    ACCESS_TYPE,
    AMENITIES_TYPE,
    CAMPING_TYPES,
    CAMPSITE_CAMPING_TYPE,
    CAMPSITE_DAYUSE_TYPE,
    ELECTRIC_TYPE,
    FCFS_TYPE,
    KEY_CHARACTERISTICS_TYPE,
    OCCUPANTS_TYPE,
    SEARCH_FILTER_CAMPSITE_ELECTRICAL,
    SPECIAL_SITE_TYPE,
    VEHICLE_LENGTH_TYPE,
} from '../constants/filters/camping';
import {
    INVENTORY_TYPES_CAMPING,
    INVENTORY_TYPES_DAY_USE,
    INVENTORY_TYPES_FILTERS,
} from '../constants/filters/inventoryTypes';
import { searchDate } from '../utils/dateUtils';

const nearByDictionary = ['nearme', 'nearby', 'near me', 'near by'];

const MAX_GEO_RESULTS = 300;

const inventoryTypeFilters = [
    {
        key: 'camping',
        value: 'Camping/Lodging',
        filters: [
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'Overnight,na',
            },
            {
                attribute: 'entity_type',
                filterValue: 'campground',
            },
            {
                attribute: '-entity_id',
                filterValue:
                    '(10238170 OR 10238165 OR 10249189 OR 10241987 OR 10241936 OR 10241919 OR 10241911 OR 10241557 OR 10241626 OR 10241695 OR 10241754 OR 10241801 OR 10241839 OR 10241885 OR 10241903)',
            },
        ],
    },
    {
        key: 'dayuse',
        value: 'Day Use',
        filters: [
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'Day',
            },
            {
                attribute: 'entity_type',
                filterValue: 'campground',
            },
        ],
    },
    {
        key: 'tours',
        value: 'Tours',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'tour, timedentry',
            },
        ],
    },
    {
        key: 'tickettours',
        value: 'Tickets',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'ticketfacility',
            },
        ],
    },
    {
        key: 'permits',
        value: 'Permits',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'permit,vehiclepermit',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'pass',
        value: 'Pass',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'pass,activitypass',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'activitypass',
        value: 'Activity Pass',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'activitypass',
            },
        ],
    },
    {
        key: 'treepermit',
        value: 'Tree Permits',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'treepermit',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'venuereservations',
        value: 'Venues',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'venuereservations,venuereservations_venue',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'program',
        value: 'Programs',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'program,program_session',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'giftcard',
        value: 'Gift Cards',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'giftcard',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'poi',
        value: 'Points of Interest',
        filters: [
            {
                attribute: 'reservable',
                filterValue: '0',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'recarea',
        value: 'Rec Area',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'recarea',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
];

function isBlank(val) {
    return !val || val === '';
}

// fetch doesn't handle errors well
function status(response) {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    }

    LoggingHelper.logError(response.statusText);
    return Promise.reject(new Error(response.statusText));
}

// parse fetch response to json
function parseJson(response) {
    return response.json();
}

// helper method to dispatch UI errors
function searchFailed(message) {
    return {
        type: types.GET_SEARCH_ERROR,
        payload: message,
        error: true,
    };
}

// mark search result item as highlighted
export function highlightSearchResultItem(itemId) {
    return {
        type: types.HIGHLIGHT_SEARCH_RESULT_ITEM,
        payload: itemId,
    };
}

// update checkin/checkout dates
export function updateSelectedDate(time, isCheckin) {
    return (dispatch, getState) => {
        const state = getState();
        const checkinTimeCustom = get(state, 'search.checkin_time_custom');
        const checkoutTimeCustom = get(state, 'search.checkout_time_custom');

        if (isCheckin) {
            if (!time && checkinTimeCustom) {
                dispatch(updateSearchCriteria('checkin_time_custom', null));
            }
            return dispatch({
                type: types.UPDATE_CHECKIN_DATE,
                time,
            });
        }

        if (!time && checkoutTimeCustom) {
            dispatch(updateSearchCriteria('checkout_time_custom', null));
        }

        return dispatch({
            type: types.UPDATE_CHECKOUT_DATE,
            time,
        });
    };
}

// update search criteria state with a new value for a name parameter
export function updateSearchCriteria(name, value) {
    return (dispatch) => {
        return dispatch({
            type: types.UPDATE_SEARCH_CRITERIA,
            name,
            value,
        });
    };
}

// update search criteria state with a new set of values
export function updateSearchCriterias(params) {
    return (dispatch) => {
        const st = { ...params };
        st.type = types.UPDATE_SEARCH_CRITERIAS;
        return dispatch(st);
    };
}

// update search criteria by adding a filter
export function addSearchFilter(uiFilterName, uiFilterOption) {
    return {
        type: types.ADD_SEARCH_FILTER,
        uiFilterName,
        uiFilterOption,
    };
}

// update search criteria by setting a filter value
export function setSearchFilter(uiFilterName, uiFilterOption) {
    return {
        type: types.SET_SEARCH_FILTER,
        uiFilterName,
        uiFilterOption,
    };
}

// update search criteria by removing a filter
export function removeSearchFilter(uiFilterName, uiFilterOption) {
    return {
        type: types.REMOVE_SEARCH_FILTER,
        uiFilterName,
        uiFilterOption,
    };
}

// reset search filters (other than what/where/lat/lng)
export function resetSearchFilters() {
    return {
        type: types.RESET_SEARCH_FILTERS,
    };
}

export function removeTourTimesFilter(options) {
    return (dispatch) => {
        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                dispatch(removeSearchFilter('tourTimes', options[key]));
            }
        }
    };
}

// update search criteria by toggling filter value
export function toggleSearchFilter(uiFilterName, uiFilterOption) {
    return (dispatch, getState) => {
        const filterOptions = getState().search.uiFilters[uiFilterName];

        const removeFilter = !!filterOptions[uiFilterOption.key];

        if (removeFilter) {
            return dispatch(removeSearchFilter(uiFilterName, uiFilterOption));
        }

        return dispatch(addSearchFilter(uiFilterName, uiFilterOption));
    };
}

// reset search filters (other than what/where/lat/lng)
export function resetSearchFiltersCampsites() {
    return {
        type: types.RESET_SEARCH_FILTERS_CAMPSITES,
    };
}

// Fetch suggestions, using search service API
export function fetchSuggestions(prefix) {
    return (dispatch) => {
        return fetch(
            utils.urlWithParams(`${process.env.API}/search/suggest`, { q: prefix }),
            { credentials: 'include' }
        )
            .then(status)
            .then(parseJson)
            .then((json) => {
                if (json.err) {
                    dispatch(searchFailed('Error search results'));
                } else {
                    dispatch({
                        type: types.GET_SEARCH_SUGGESTIONS,
                        suggestions: json.suggestions,
                        inventory_suggestions: json.inventory_suggestions,
                    });
                }
            })
            .catch((error) => {
                dispatch(searchFailed(`Error fetching data: ${error}`));
            });
    };
}

function buildTourTimesFilter(uiFilter) {
    const filterValues = [];

    if (uiFilter && uiFilter.tourTimes) {
        const tourTimesFilter = uiFilter.tourTimes;

        for (const key in tourTimesFilter) {
            if (tourTimesFilter.hasOwnProperty(key)) {
                const item = tourTimesFilter[key];
                if (Array.isArray(item.filters)) {
                    item.filters.forEach((filter) => {
                        if (filter.hasOwnProperty('filterValue')) {
                            filterValues.push(filter.filterValue);
                        }
                    });
                }
            }
        }
    }

    return filterValues;
}

// build filters from search state
export function buildSearchFilters(baseFilters, UIFilters) {
    const uiFilters = cloneDeep(UIFilters);
    const ret = [];
    // append raw base filters
    if (baseFilters) {
        baseFilters.forEach((filter) => {
            filter.values.forEach((value) => {
                const parts = value.value.split(',');
                parts.forEach((part) => {
                    if (part.startsWith('>') || part.startsWith('<')) {
                        // range filters
                        ret[ret.length] = `${filter.attribute}${part}`;
                    } else {
                        // term filters
                        ret[ret.length] = `${filter.attribute}:${part}`;
                    }
                });
            });
        });
    }

    if (uiFilters && uiFilters.tourTimes) {
        delete uiFilters.tourTimes;
    }

    // convert and append uiFilters to filters
    if (uiFilters) {
        // only using specific filter instead of general
        if (
            !isEmpty(uiFilters.campsiteElectricityHookup) &&
            uiFilters.campsiteAmenities &&
            !isEmpty(uiFilters.campsiteAmenities['campsite-amenities-electricity-hookup'])
        ) {
            delete uiFilters.campsiteAmenities['campsite-amenities-electricity-hookup'];
        }
        for (const propName in uiFilters) {
            if (isObject(uiFilters[propName]) && !isEmpty(uiFilters[propName])) {
                for (const key in uiFilters[propName]) {
                    if (key in uiFilters[propName]) {
                        const uiFilter = uiFilters[propName][key];
                        uiFilter.filters.forEach((filter) => {
                            let alreadySet = false;
                            baseFilters.forEach((f) => {
                                if (filter.attribute === f.attribute) {
                                    alreadySet = true;
                                }
                            });
                            if (!alreadySet) {
                                const parts = filter.filterValue.split(',');
                                parts.forEach((part) => {
                                    if (part.startsWith('>') || part.startsWith('<')) {
                                        // range filters
                                        ret[ret.length] = `${filter.attribute}${part}`;
                                    } else {
                                        // term filters
                                        ret[ret.length] = `${filter.attribute}:${part}`;
                                    }
                                });
                            }
                        });
                    }
                }
            }
        }
    }

    return ret;
}

// helper method to construct search GET uri (the uri is the same for search service call as well as for front end)
function buildSearchServiceRequest(baseUri, state, skipStartParam) {
    // build filters
    const filters = buildSearchFilters(state.search.filters, state.search.uiFilters);
    const tourTimes = buildTourTimesFilter(state.search.uiFilters);
    if (state.search.parent_asset_id) {
        filters[filters.length] = `parent_asset_id:${state.search.parent_asset_id}`;
    }
    if (state.search.org_id) {
        filters[filters.length] = `org_id:${state.search.org_id}`;
    }

    // build aggregations
    const aggs = [];
    state.search.aggregations.forEach((agg) => {
        aggs[aggs.length] = agg;
    });

    // create request params
    const params = {
        q: state.search.what || state.search.headerTextQuery,
        entity_id: state.search.entity_id,
        entity_type: state.search.entity_type,
        exact: state.search.exact,
        lat: state.search.lat,
        lng: state.search.lng,
        radius: state.search.radius,
        location: state.search.location,
        lat_sw: state.search.lat_sw,
        lng_sw: state.search.lng_sw,
        lat_ne: state.search.lat_ne,
        lng_ne: state.search.lng_ne,
        size: state.search.size != null ? state.search.size : 20,
        agg: aggs,
        fq: filters,
        group_agg: state.search.group_agg,
        fg: state.search.fg,
        tour_times: tourTimes,
        sort: state.search.sort,
    };

    if (state.search.avail_nights) {
        params.avail_nights = state.search.avail_nights;
    }

    if (state.search.avail_months) {
        params.avail_months = state.search.avail_months;
    }

    if (state.search.flex_start_range) {
        params.flex_start_range = state.search.flex_start_range;
    }

    if (state.search.flex_end_range) {
        params.flex_end_range = state.search.flex_end_range;
    }

    if (!skipStartParam) {
        params.start = state.search.start !== null ? state.search.start : 0;
    }

    const includeUnavailable = get(state.search, 'include_unavailable', true);
    const includeNotReservable = get(state.search, 'include_notreservable', true);
    const includePartiallyAvailable = get(
        state.search,
        'include_partially_available',
        true
    );

    if (
        includeNotReservable === false ||
        includeUnavailable === false ||
        includePartiallyAvailable === false
    ) {
        params.fq[params.fq.length] = 'reservable:1';
    }

    if (state.search.checkin_time && state.search.checkout_time) {
        params.start_date = searchDate(state.search.checkin_time)
            .utc()
            .startOf('day')
            .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
        params.end_date = searchDate(state.search.checkout_time)
            .utc()
            .startOf('day')
            .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    }

    if (includeNotReservable === false) {
        params.include_notreservable = includeNotReservable;
    }

    if (includeUnavailable === false) {
        params.include_unavailable = includeUnavailable;
    }

    if (includePartiallyAvailable === false) {
        params.include_partially_available = includePartiallyAvailable;
    }

    return utils.urlWithParams(baseUri, params);
}

function parseFgForQuery(props) {
    const fg = get(props, 'search.fg', []);
    const fgParams = {};
    if (fg.length > 0) {
        const getPathFg = (filters, key) => filters.find((item) => item.key === key);
        fg.forEach((v) => {
            const campingType = getPathFg(CAMPING_TYPES, v);
            if (campingType) {
                fgParams.campsite_type = [
                    ...get(fgParams, 'campsite_type', []),
                    campingType.value.toLowerCase(),
                ];
            }
            const electricityType = getPathFg(ELECTRIC_TYPE, v);
            if (electricityType) {
                fgParams.electricity = [
                    ...get(fgParams, 'electricity', []),
                    electricityType.value.toLowerCase(),
                ];
            }
            const amenitiesType = getPathFg(AMENITIES_TYPE, v);
            if (amenitiesType) {
                fgParams.amenities = [
                    ...get(fgParams, 'amenities', []),
                    amenitiesType.value.toLowerCase(),
                ];
            }
            const electricalHookup = getPathFg(SEARCH_FILTER_CAMPSITE_ELECTRICAL, v);
            if (electricalHookup) {
                fgParams.electrical_hookup = [
                    ...get(fgParams, 'electrical_hookup', []),
                    electricalHookup.value.toLowerCase(),
                ];
            }
            const keyCharacteristics = getPathFg(KEY_CHARACTERISTICS_TYPE, v);
            if (keyCharacteristics) {
                fgParams.key_characteristics = [
                    ...get(fgParams, 'key_characteristics', []),
                    keyCharacteristics.value.toLowerCase(),
                ];
            }
            const accessType = getPathFg(ACCESS_TYPE, v);
            if (accessType) {
                fgParams.access = [...get(fgParams, 'access', []), accessType.value];
            }
            const specialSiteType = getPathFg(SPECIAL_SITE_TYPE, v);
            if (specialSiteType) {
                fgParams.special_site = [
                    ...get(fgParams, 'special_site', []),
                    specialSiteType.value.toLowerCase(),
                ];
            }
            const inventory = getPathFg(INVENTORY_TYPES_FILTERS, v);
            if (inventory) {
                fgParams.inventory_type = [
                    ...get(fgParams, 'inventory_type', []),
                    inventory.value.toLowerCase(),
                ];
            }
            const firstComeFirstServe = getPathFg(FCFS_TYPE, v);
            if (firstComeFirstServe) {
                fgParams.fcfs = [
                    ...get(fgParams, 'fcfs', []),
                    firstComeFirstServe.value.toLowerCase(),
                ];
            }
            if (v.includes(VEHICLE_LENGTH_TYPE.key)) {
                const value = v.split(':');
                fgParams[VEHICLE_LENGTH_TYPE.key] = value[1];
            }
            if (v.includes(OCCUPANTS_TYPE.key)) {
                const value = v.split(':');
                fgParams[OCCUPANTS_TYPE.key] = value[1];
            }
            if (v === CAMPSITE_DAYUSE_TYPE) {
                fgParams.booking_type = [
                    ...get(fgParams, 'booking_type', []),
                    INVENTORY_TYPES_DAY_USE,
                ];
            }
            if (v === CAMPSITE_CAMPING_TYPE) {
                fgParams.booking_type = [
                    ...get(fgParams, 'booking_type', []),
                    INVENTORY_TYPES_CAMPING,
                ];
            }
        });
    }
    return fgParams;
}

function generateUiFiltersForQueryArray(value, key) {
    const params = {};
    Object.values(value)?.forEach((v) => {
        const current = get(params, key, []);
        params[key] = [...current, v.value?.toLowerCase()];
    });
    return params;
}

function parseUiFiltersForQuery(props) {
    let uiFiltersParams = {};
    const uiFilters = get(props, 'search.uiFilters', {});
    Object.entries(uiFilters).forEach((item) => {
        const [key, value] = item;
        if (typeof value === 'object' && !isEmpty(value)) {
            const current = types.uiFiltersGlossary.find((v) => v.value === key);
            if (key !== 'activities' && current) {
                uiFiltersParams = {
                    ...uiFiltersParams,
                    ...generateUiFiltersForQueryArray(value, current.key),
                };
            } else if (key === 'activities') {
                uiFiltersParams = {
                    ...uiFiltersParams,
                    ...generateUiFiltersForQueryArray(value, 'activity'),
                };
            }
        }
    });
    return uiFiltersParams;
}

// helper method to construct search GET uri (the uri is the same for search service call as well as for front end)
export function buildSearchNavUri(baseUri, props) {
    let activitiesParam = null;
    const activitiesFilters = get(props, 'search.uiFilters.activities', {});
    const pActivity = get(props, 'search.activity');
    if (
        pActivity &&
        !isEmpty(activitiesFilters) &&
        lodashSize(isArray(pActivity) ? pActivity : [pActivity]) ===
            lodashSize(activitiesFilters)
    ) {
        activitiesParam = pActivity;
    }

    let campsiteEquipmentParam = null;
    const campsiteEquipmentFilters = get(props, 'search.uiFilters.campsiteEquipment', {});
    const pCampsiteEquipment = get(props, 'search.campsite_equipment');
    if (
        pCampsiteEquipment &&
        !isEmpty(campsiteEquipmentFilters) &&
        lodashSize(
            isArray(pCampsiteEquipment) ? pCampsiteEquipment : [pCampsiteEquipment]
        ) === lodashSize(campsiteEquipmentFilters)
    ) {
        campsiteEquipmentParam = pCampsiteEquipment;
    }

    let campsiteAmenitiesParam = null;
    const campsiteAmenitiesFilters = get(props, 'search.uiFilters.campsiteAmenities', {});
    const pCampsiteAmenities = get(props, 'search.campsite_amenity');
    if (
        pCampsiteAmenities &&
        !isEmpty(campsiteAmenitiesFilters) &&
        lodashSize(
            isArray(pCampsiteAmenities) ? pCampsiteAmenities : [pCampsiteAmenities]
        ) === lodashSize(campsiteAmenitiesFilters)
    ) {
        campsiteAmenitiesParam = pCampsiteAmenities;
    }

    let campsiteTypeParam = null;
    const campsiteTypeFilters = get(props, 'search.uiFilters.campsiteTypes', {});
    const pCampsiteType = get(props, 'search.campsite_type');
    if (
        pCampsiteType &&
        !isEmpty(campsiteTypeFilters) &&
        lodashSize(isArray(pCampsiteType) ? pCampsiteType : [pCampsiteType]) ===
            lodashSize(campsiteTypeFilters)
    ) {
        campsiteTypeParam = pCampsiteType;
    }
    const uiFilterParams = parseUiFiltersForQuery(props);
    const fgParams = parseFgForQuery(props);
    const params = {
        q: props.search.what,
        entity_id: props.search.entity_id,
        entity_type: props.search.entity_type,
        lat: props.search.lat,
        lng: props.search.lng,
        radius: props.search.radius,
        lat_sw: props.search.lat_sw,
        lng_sw: props.search.lng_sw,
        lat_ne: props.search.lat_ne,
        lng_ne: props.search.lng_ne,
        start: props.search.start,
        size: props.search.size,
        inventory_type: props.search.inventory_type,
        activity: activitiesParam,
        campsite_equipment: campsiteEquipmentParam,
        campsite_amenity: campsiteAmenitiesParam,
        campsite_type: campsiteTypeParam,
        parent_asset_id: props.search.parent_asset_id,
        org_id: props.search.org_id,
        ...uiFilterParams,
        ...fgParams,
        sort: props.search.sort,
        checkin: props.search.checkin_time,
        checkout: props.search.checkout_time,
        utm_source: props.search.utm_source,
        utm_medium: props.search.utm_medium,
        utm_campaign: props.search.utm_campaign,
        utm_content: props.search.utm_content,
        avail_nights: props.search.avail_nights,
        avail_months: props.search.avail_months,
        flex_start_range: props.search.flex_start_range,
        flex_end_range: props.search.flex_end_range,
    };

    return utils.urlWithParams(baseUri, params);
}

// fetch aggregations for attributes of a UI Filter
export function fetchFilterAggregations(
    uiFilterName,
    aggregateAttributes,
    additionalFilters,
    isCampsites,
    useSelectedFilters = false
) {
    return (dispatch, getState) => {
        const state = cloneState(getState());

        const params = {
            search: {
                filters: [],
            },
        };

        params.search = { ...state.search };
        if (!useSelectedFilters) {
            params.search.filters = [];
        }

        if (additionalFilters) {
            additionalFilters.forEach((filter) => {
                params.search.filters[params.search.filters.length] = filter;
            });
        }

        params.search.aggregations = aggregateAttributes;
        params.search.size = 0;
        params.search.sort = null;
        params.search.fg = [];

        let searchUrl;

        if (!isCampsites) {
            if (!state.search.lat && state.searchResults.lat) {
                params.search.lat = state.searchResults.lat;
                params.search.lng = state.searchResults.lng;
                params.search.radius = state.searchResults.radius;
                params.search.location = state.searchResults.location;
            }

            searchUrl = `${process.env.API}/search`;
            // include all but filters for current uiFilterName
            const uiFilters = {};
            if (useSelectedFilters) {
                for (const propName in params.search.uiFilters) {
                    if (propName !== uiFilterName) {
                        uiFilters[propName] = params.search.uiFilters[propName];
                    }
                }
            }
            params.search.uiFilters = uiFilters;
        } else {
            searchUrl = `${process.env.API}/search/campsites`;
            // include only campsite* filters and exclude current uiFiltersName
            const uiFilters = {};
            if (useSelectedFilters) {
                for (const propName in params.search.uiFilters) {
                    // filter out campsite specific filters only
                    if (
                        propName !== uiFilterName &&
                        (propName.startsWith('campsite') ||
                            propName === 'occupants' ||
                            propName === 'reservable')
                    ) {
                        uiFilters[propName] = params.search.uiFilters[propName];
                    }
                }
            }
            params.search.uiFilters = uiFilters;

            // remove keyword search
            params.search.what = null;

            // campsite aggregations don't need availability search
            params.search.checkin_time = null;
            params.search.checkout_time = null;
            params.search.lat = null;
            params.search.lng = null;
            params.search.radius = null;
            params.search.location = null;
            params.search.lat_sw = null;
            params.search.lng_sw = null;
            params.search.lat_ne = null;
            params.search.lng_ne = null;
        }

        const searchServiceUrl = buildSearchServiceRequest(searchUrl, params);

        return fetch(searchServiceUrl, { credentials: 'include' })
            .then(status)
            .then(parseJson)
            .then((json) => {
                if (json.err) {
                    dispatch(searchFailed('Error search results'));
                } else {
                    dispatch({
                        type: types.GET_SEARCH_FILTER_AGGREGATIONS,
                        uiFilterName,
                        payload: json,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: types.END_GET_SEARCH_RESULTS });
                dispatch(searchFailed(`Error fetching data: ${error}`));
            });
    };
}

// fetch group aggregations for attributes of a UI Filter
export function fetchFilterGroupAggregations(
    groupName,
    invType,
    isCampsites,
    additionalFilters,
    omitFg
) {
    return (dispatch, getState) => {
        const state = cloneState(getState());

        const params = {
            search: {
                filters: [
                    {
                        attribute: '-entity_type',
                        values: [{ value: '(tour OR timedentry_tour)' }],
                    },
                ],
            },
        };

        params.search = { ...state.search };

        params.search.group_agg = groupName;
        params.search.size = 0;
        params.search.sort = null;

        let searchUrl = `${process.env.API}/search`;

        if (!state.search.lat && state.searchResults?.lat) {
            params.search.lat = state.searchResults.lat;
            params.search.lng = state.searchResults.lng;
            params.search.radius = state.searchResults.radius;
            params.search.location = state.searchResults.location;
        }

        if (additionalFilters) {
            additionalFilters.forEach((filter) => {
                params.search.filters[params.search.filters.length] = filter;
            });
        }

        const legacyKeys = [
            'campsiteTypes',
            'campsiteAmenities',
            'campsiteElectricityHookup',
            'campsiteEquipment',
        ];

        const uiFilters = {};
        if (params.search?.uiFilters) {
            for (const propName in params.search?.uiFilters) {
                if (legacyKeys.indexOf(propName) === -1) {
                    uiFilters[propName] = params.search.uiFilters[propName];
                }
            }
        }

        params.search.uiFilters = uiFilters;

        if (isCampsites) {
            const parsedFg = parseCampsiteFilters(get(params, 'search.fg', []));
            searchUrl = `${process.env.API}/search/campsites`;
            params.search.what = null;
            params.search.headerTextQuery = null;
            params.search.q = null;
            params.search.fq = [``];
            params.search.checkin_time = null;
            params.search.checkout_time = null;
            params.search.lat = null;
            params.search.lng = null;
            params.search.radius = null;
            params.search.location = null;
            params.search.lat_sw = null;
            params.search.lng_sw = null;
            params.search.lat_ne = null;
            params.search.lng_ne = null;
            params.search.fg = parsedFg;
        }

        if (omitFg) {
            params.search.fg = [];
        }

        const searchServiceUrl = buildSearchServiceRequest(searchUrl, params);
        const initialAggregations = [{ name: 'camping', count: 0 }];

        return fetch(searchServiceUrl, { credentials: 'include' })
            .then(status)
            .then(parseJson)
            .then((json) => {
                if (json.err) {
                    dispatch(searchFailed('Error search results'));
                } else {
                    let payload = {};
                    const result = get(
                        json,
                        'aggregations["group-aggregations"]',
                        initialAggregations
                    );
                    result.forEach((item) => {
                        payload = { ...payload, [item.name]: item.count };
                    });

                    dispatch({
                        type: types.GET_SEARCH_FILTER_GROUP_AGGREGATIONS,
                        groupName: invType,
                        aggregations: payload,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: types.GET_SEARCH_FILTER_GROUP_AGGREGATIONS,
                    groupName: invType,
                    aggregations: initialAggregations,
                });
                dispatch(searchFailed(`Error fetching data: ${error}`));
            });
    };
}

// fetch inventory types aggregations
export function fetchInventoryTypesAggregations() {
    return async (dispatch, getState) => {
        const ldClient = getState()?.launchdarkly?.ldClient;
        await ldClient?.waitUntilReady();
        const enableGroupAggregations = ldClient?.allFlags()['useNewCampingFilters'];
        if (enableGroupAggregations) {
            dispatch(
                fetchFilterGroupAggregations(
                    ['booking-types'],
                    'inventoryTypes',
                    false,
                    null,
                    true
                )
            );
        }
        if (!enableGroupAggregations) {
            dispatch(
                fetchFilterAggregations(
                    'campsiteUseTypes',
                    ['campsite_type_of_use'],
                    [
                        {
                            attribute: 'entity_type',
                            values: [{ label: '', value: 'campground' }],
                        },
                    ],
                    false,
                    false
                )
            );
            dispatch(
                fetchFilterAggregations(
                    'entityTypes',
                    ['entity_type'],
                    null,
                    false,
                    false
                )
            );
        }
    };
}

// fetch aggregations for ui search filters
export function fetchSearchFiltersAggregations() {
    return async (dispatch, getState) => {
        const ldClient = getState().launchdarkly.ldClient;
        await ldClient.waitUntilReady();
        const enableGroupAggregations = ldClient.allFlags()['useNewCampingFilters'];
        dispatch(fetchFilterAggregations('activities', ['asset_activities']));

        if (enableGroupAggregations) {
            dispatch(
                fetchFilterGroupAggregations(
                    [
                        'camping-type',
                        'electricity',
                        'key-characteristics',
                        'access',
                        'special-site-type',
                        'amenities',
                    ],
                    'camping'
                )
            );
        }
        if (!enableGroupAggregations) {
            dispatch(
                fetchFilterAggregations(
                    'campsiteTypes',
                    ['campsite_type', 'campsite_equipment_name'],
                    [
                        {
                            attribute: 'entity_type',
                            values: [{ label: '', value: 'campground' }],
                        },
                    ]
                )
            );

            dispatch(
                fetchFilterAggregations(
                    'campsiteAmenities',
                    [
                        'att_fire_pit',
                        'att_toilet',
                        'campsite_type',
                        'att_pets_allowed',
                        'att_driveway_entry',
                        'att_sewer_hookup',
                        'att_shade',
                        'att_water_hookup',
                        'att_shower_bath_type',
                        'att_proximity_to_water',
                        'att_electricity_hookup',
                        'campsite_accessible',
                    ],
                    [
                        {
                            attribute: 'entity_type',
                            values: [{ label: '', value: 'campground' }],
                        },
                    ]
                )
            );

            dispatch(
                fetchFilterAggregations(
                    'campsiteEquipment',
                    ['campsite_equipment_name'],
                    [
                        {
                            attribute: 'entity_type',
                            values: [{ label: '', value: 'campground' }],
                        },
                    ]
                )
            );

            dispatch(
                fetchFilterAggregations(
                    'campsiteElectricityHookup',
                    ['att_electricity_hookup'],
                    [
                        {
                            attribute: 'entity_type',
                            values: [{ label: '', value: 'campground' }],
                        },
                    ]
                )
            );
        }

        dispatch(
            fetchFilterAggregations(
                'tourTypes',
                ['tour_type'],
                [
                    {
                        attribute: 'entity_type',
                        values: [{ label: '', value: 'tour' }],
                    },
                ]
            )
        );

        dispatch(
            fetchFilterAggregations(
                'tourDifficulty',
                ['att_physical_effort'],
                [
                    {
                        attribute: 'entity_type',
                        values: [{ label: '', value: 'tour' }],
                    },
                ]
            )
        );

        dispatch(
            fetchFilterAggregations(
                'tourAccessible',
                ['tour_accessible'],
                [
                    {
                        attribute: 'entity_type',
                        values: [{ label: '', value: 'tour' }],
                    },
                ]
            )
        );

        dispatch(
            fetchFilterAggregations(
                'permitTypes',
                ['permit_activities'],
                [
                    {
                        attribute: 'entity_type',
                        values: [{ label: '', value: 'permit' }],
                    },
                ]
            )
        );

        dispatch(fetchFilterAggregations('agencies', ['org_id']));
    };
}

// fetch aggregations for ui search filters for campsites search
function fetchCampsitesSearchFiltersAggregationsLegacy(campgroundId) {
    return (dispatch) => {
        dispatch(
            fetchFilterAggregations(
                'campsiteUseTypes',
                ['campsite_type_of_use'],
                [
                    {
                        attribute: 'asset_id',
                        values: [{ label: '', value: campgroundId }],
                    },
                ],
                true
            )
        );

        dispatch(
            fetchFilterAggregations(
                'campsiteTypes',
                ['campsite_type', 'campsite_equipment_name'],
                [
                    {
                        attribute: 'asset_id',
                        values: [{ label: '', value: campgroundId }],
                    },
                ],
                true
            )
        );

        dispatch(
            fetchFilterAggregations(
                'campsiteAmenities',
                [
                    'att_fire_pit',
                    'att_toilet',
                    'campsite_type',
                    'att_pets_allowed',
                    'att_driveway_entry',
                    'att_sewer_hookup',
                    'att_shade',
                    'att_water_hookup',
                    'att_proximity_to_water',
                    'att_electricity_hookup',
                    'campsite_accessible',
                ],
                [
                    {
                        attribute: 'asset_id',
                        values: [{ label: '', value: campgroundId }],
                    },
                ],
                true
            )
        );

        dispatch(
            fetchFilterAggregations(
                'campsiteEquipment',
                ['campsite_equipment_name'],
                [
                    {
                        attribute: 'asset_id',
                        values: [{ label: '', value: campgroundId }],
                    },
                ],
                true
            )
        );

        dispatch(
            fetchFilterAggregations(
                'campsiteElectricityHookup',
                ['att_electricity_hookup'],
                [
                    {
                        attribute: 'asset_id',
                        values: [{ label: '', value: campgroundId }],
                    },
                ],
                true
            )
        );
    };
}

export function fetchCampsitesSearchFiltersAggregationsWithFlag(
    campgroundId,
    enableCampingRevamp
) {
    return async (dispatch, getState) => {
        const ldClient = getState()?.launchdarkly?.ldClient;
        await ldClient?.waitUntilReady();
        const enableGroupAggregations =
            ldClient && ldClient?.allFlags()['useNewCampingFilters'];
        if (enableGroupAggregations || enableCampingRevamp) {
            dispatch(
                fetchFilterGroupAggregations(
                    [
                        'camping-type',
                        'electricity',
                        'key-characteristics',
                        'access',
                        'special-site-type',
                        'amenities',
                        'booking-types',
                    ],
                    'camping',
                    true,
                    [
                        {
                            attribute: 'asset_id',
                            values: [{ label: '', value: campgroundId }],
                        },
                    ]
                )
            );
        } else {
            dispatch(fetchCampsitesSearchFiltersAggregationsLegacy(campgroundId));
        }
    };
}

export function fetchCampsitesSearchFiltersAggregations(
    campgroundId,
    dispatch,
    enableCampingRevamp
) {
    dispatch(
        fetchCampsitesSearchFiltersAggregationsWithFlag(campgroundId, enableCampingRevamp)
    );
}

// fetch geo/map specific search results using current search criteria state
export function fetchSearchGEOResults() {
    return (dispatch, getState) => {
        /*
        const params = {
            q: state.search.what,
            entity_id: state.search.entity_id,
            entity_type: state.search.entity_type,
            exact: state.search.exact,
            where: state.search.where,
            lat: state.search.lat,
            lng: state.search.lng,
            radius: state.search.radius,
            location: state.search.location,
            lat_sw: state.search.lat_sw,
            lng_sw: state.search.lng_sw,
            lat_ne: state.search.lat_ne,
            lng_ne: state.search.lng_ne,
            start: state.search.start != null ? state.search.start : 0,
            size: state.search.size != null ? state.search.size : 20,
            agg: aggs,
            fq: filters,
            sort: state.search.sort,
            placeid: state.search.placeid
        };

        if (state.search.checkin_time && state.search.checkout_time) {
            params.include_unavailable = state.search.include_unavailable;
        }

        return utils.urlWithParams(baseUri, params);
        */

        // geocode place if it's selected but lat/lng are not available
        const state = getState();

        const params = {
            search: {
                ...state.search,
            },
        };

        let size = params.search.size;

        if (!size) {
            size = 20;
        }

        const count = params.search.start + size;
        const page = Math.floor(count / MAX_GEO_RESULTS);
        let skipStart = true;

        // TODO: Needs to be more robust
        if (page > 0) {
            params.search.start = MAX_GEO_RESULTS * page;
            skipStart = false;
        }

        // exclude individual tours from results
        params.search.filters = [
            {
                attribute: '-entity_type',
                values: [{ value: '(tour OR timedentry_tour)' }],
            },
        ];

        params.search.size = MAX_GEO_RESULTS;

        const searchServiceUrl = buildSearchServiceRequest(
            `${process.env.API}/search/geo`,
            params,
            skipStart
        );

        dispatch({ type: types.START_GET_GEO_SEARCH_RESULTS });

        return fetch(searchServiceUrl, { credentials: 'include' })
            .then(status)
            .then(parseJson)
            .then((json) => {
                if (json.err) {
                    dispatch(searchFailed('Error search results'));
                } else {
                    dispatch({
                        type: types.GET_SEARCH_RESULTS,
                        payload: { geoResults: json.results },
                    });
                }

                dispatch({ type: types.END_GET_GEO_SEARCH_RESULTS });
            })
            .catch((error) => {
                dispatch({ type: types.END_GET_GEO_SEARCH_RESULTS });
                dispatch(searchFailed(`Error fetching data: ${error}`));
            });
    };
}

// fetch search results using current search criteria state
export function fetchSearchResults() {
    return (dispatch, getState) => {
        // geocode place if it's selected but lat/lng are not available
        const state = { ...getState() };
        let lat = state.search.lat;
        let lng = state.search.lng;
        let radius = state.search.radius;
        let location = state.search.location;

        // geocode client request on client side, if needed
        let $geocode = new Promise((resolve) => {
            dispatch({ type: types.START_GET_SEARCH_RESULTS });
            resolve();
        });

        // check input string for 'near by' type word combinations
        let nearByLocation = false;
        if (state.search.what) {
            const q = state.search.what.toLowerCase();
            nearByDictionary.forEach((word) => {
                if (q.indexOf(word) >= 0) {
                    nearByLocation = true;
                }
            });
        }

        let geocodeUserLocation = false;
        if (isBlank(state.search.lat) && (isBlank(state.search.what) || nearByLocation)) {
            if (
                state.search.hasOwnProperty('use_location') &&
                !state.search.use_location
            ) {
                lat = null;
                lng = null;
                radius = null;
                location = null;
            } else {
                geocodeUserLocation = true;
            }
        }

        // geocode current user position if no other location specific parameters are defined in search request
        if (geocodeUserLocation) {
            $geocode = new Promise((resolve) => {
                dispatch({ type: types.START_GET_SEARCH_RESULTS });

                function onPositionSuccess(position) {
                    if (position) {
                        if (position.location && position.location.city) {
                            location = `${position.location.city}, ${position.location.region_name}`;
                        } else {
                            location = null;
                        }
                        lat = position.latitude;
                        lng = position.longitude;

                        // should search only within 150 mi from the user's location.
                        const latNe = lat + 0.85;
                        const lngNe = lng + 0.85;
                        const latSw = lat - 0.85;
                        const lngSw = lng - 0.85;
                        dispatch(
                            updateSearchCriterias({
                                lat,
                                lng,
                                location,
                                radius: null,
                                placeid: null,
                                placename: null,
                                where: null,
                                latNe,
                                lngNe,
                                latSw,
                                lngSw,
                            })
                        );
                    }
                }

                // if position is in cache, then return it
                if (sessionStorage) {
                    let browserPosition = sessionStorage.getItem('browserPosition');
                    if (browserPosition) {
                        browserPosition = JSON.parse(browserPosition);
                        onPositionSuccess({
                            latitude: browserPosition.latitude,
                            longitude: browserPosition.longitude,
                            location: browserPosition.location,
                        });
                        resolve();
                        return;
                    }
                }

                // try ip geocoder
                fetch(`${process.env.API}/recommendation/geoip`)
                    .then(status)
                    .then(parseJson)
                    .then((json) => {
                        if (
                            json.latitude &&
                            json.longitude &&
                            json.latitude !== 0 &&
                            json.longitude !== 0
                        ) {
                            if (sessionStorage) {
                                sessionStorage.setItem(
                                    'browserPosition',
                                    JSON.stringify({
                                        latitude: json.latitude,
                                        longitude: json.longitude,
                                        location: json,
                                    })
                                );
                            }
                            onPositionSuccess({
                                latitude: json.latitude,
                                longitude: json.longitude,
                                location: json,
                            });
                            resolve();
                        } else if (navigator.geolocation) {
                            // fallback to browser location
                            navigator.geolocation.getCurrentPosition(
                                (position) => {
                                    // on success
                                    // saved fetched position in cache
                                    if (sessionStorage) {
                                        sessionStorage.setItem(
                                            'browserPosition',
                                            JSON.stringify({
                                                latitude: position.coords.latitude,
                                                longitude: position.coords.longitude,
                                            })
                                        );
                                    }
                                    onPositionSuccess({
                                        latitude: position.coords.latitude,
                                        longitude: position.coords.longitude,
                                    });
                                    resolve();
                                },
                                () => {
                                    // on error
                                    resolve();
                                },
                                {
                                    // optimization params
                                    enableHighAccuracy: false,
                                    maximumAge: Infinity,
                                    timeout: 10000,
                                }
                            );
                        } else {
                            resolve();
                        }
                    })
                    .catch(() => {
                        resolve();
                    });
            });
        }

        return $geocode.then(() => {
            const params = {
                search: {
                    ...state.search,
                    lat,
                    lng,
                    radius,
                    location,
                },
            };

            // exclude individual tours from results
            params.search.filters = [
                {
                    attribute: '-entity_type',
                    values: [{ value: '(tour OR timedentry_tour)' }],
                },
            ];

            const searchServiceUrl = buildSearchServiceRequest(
                `${process.env.API}/search`,
                params
            );

            if (state.search.filtersVisible) {
                dispatch(fetchInventoryTypesAggregations());
                dispatch(fetchSearchFiltersAggregations());
            }

            fetch(searchServiceUrl, { credentials: 'include' })
                .then(status)
                .then(parseJson)
                .then((json) => {
                    if (json.err) {
                        dispatch(searchFailed('Error search results'));
                    } else {
                        dispatch({ type: types.GET_SEARCH_RESULTS, payload: json });

                        const uri = buildSearchNavUri('/search', state);
                        window.history.replaceState({}, '', uri);

                        setTimeout(() => {
                            dispatch(fetchSearchGEOResults());
                        }, 200);
                    }

                    dispatch({ type: types.END_GET_SEARCH_RESULTS });
                })
                .catch((error) => {
                    dispatch({ type: types.END_GET_SEARCH_RESULTS });
                    dispatch(searchFailed(`Error fetching data: ${error}`));
                });
        });
    };
}

// show search results in response to a user action using current search state
export function navigateToSearchResults() {
    return (dispatch, getState) => {
        return dispatch(fetchSearchResults()).then(() => {
            const uri = buildSearchNavUri('/search', getState());
            if (window.location?.pathname.startsWith('/search')) {
                window.history.replaceState({}, '', uri);
            } else {
                window.location?.assign(uri);
            }
        });
    };
}

export function hideNavbarSearch() {
    return {
        type: types.HIDE_SEARCH_INPUT,
    };
}

export function showNavbarSearch() {
    return {
        type: types.SHOW_SEARCH_INPUT,
    };
}

// add recreational activity filter to search criteria
export function setActivityFilters(activities) {
    let filters = {};
    if (activities) {
        const values = [...activities];
        values.forEach((name) => {
            if (name) {
                const filter = {
                    key: `activity-${name.toLowerCase().replace(' ', '-')}`,
                    value: capitalize(name),
                    filters: [
                        {
                            attribute: 'asset_activities',
                            filterValue: name.toUpperCase(),
                        },
                    ],
                };
                filters = { ...filters, [filter.key]: filter };
            }
        });
    }
    return filters;
}

// add recreational agency filter to search criteria
export function setAgencyFilters(agencies) {
    let filters = {};
    if (agencies) {
        const values = [...agencies];
        values.forEach((name) => {
            if (name) {
                const filter = types.SEARCH_FILTER_AGENCIES_VALUES.find(
                    (item) => !isEmpty(item.filters.filter((v) => v.filterValue === name))
                );
                if (filter) {
                    filters = { ...filters, [filter.key]: filter };
                }
            }
        });
    }
    return filters;
}

// add campsite equipment filters to search criteria
export function setCampsiteEquipmentFilters(campsiteEquipment) {
    let filters = {};
    if (campsiteEquipment) {
        const values = [...campsiteEquipment];
        values.forEach((name) => {
            if (name) {
                const filter = find(types.CAMPSITE_EQUIPMENT_FILTERS, {
                    value: name,
                });
                if (filter) {
                    filters = { ...filters, [filter.key]: filter };
                }
            }
        });
    }
    return filters;
}

// add campsite amenity filters to search criteria
export function setCampsiteAmenityFilters(campsiteAmenities) {
    let filters = {};
    if (campsiteAmenities) {
        const values = [...campsiteAmenities];
        values.forEach((name) => {
            if (name) {
                const filter = find(types.CAMPSITE_AMENITY_FILTERS, { value: name });
                if (!filter && accessibilityFilter.value === name) {
                    filters = {
                        ...filters,
                        [accessibilityFilter.key]: accessibilityFilter,
                    };
                }
                if (filter) {
                    filters = { ...filters, [filter.key]: filter };
                }
            }
        });
    }
    return filters;
}

// add campsite type filters to search criteria
export function setCampsiteTypeFilters(campsiteTypes) {
    let filters = {};
    if (campsiteTypes) {
        const values = [...campsiteTypes];
        values.forEach((name) => {
            const filter = find(types.CAMPSITE_TYPE_FILTERS, { value: name });
            if (filter) {
                filters = { ...filters, [filter.key]: filter };
            }
        });
    }
    return filters;
}

export function setInventoryTypeFilters(selectedFilters) {
    let uiFilter = {};
    if (selectedFilters) {
        selectedFilters.forEach((name) => {
            const filter = inventoryTypeFilters.find(
                (item) => item.key.toLowerCase() === name.toLowerCase()
            );
            if (filter) {
                uiFilter = {
                    ...uiFilter,
                    [filter.key]: {
                        key: filter.key,
                        value: filter.value,
                        filters: filter.filters,
                    },
                };
            }
        });
    }
    return uiFilter;
}

export function setGlobalUiFilter(queryParams, uiFilters) {
    const params = {};
    const clearFilters = isArray(queryParams) ? queryParams : [queryParams];
    clearFilters?.forEach((item) => {
        const current = uiFilters.find(
            (v) => v?.value?.toLowerCase() === item?.toLowerCase()
        );
        if (current) {
            params[current.key] = current;
        }
    });
    return params;
}

function parseUiFiltersFromQuery(props, uiFilters) {
    let uiFiltersParams = uiFilters;
    if (props.activity) {
        const activities = isArray(props.activity) ? props.activity : [props.activity];
        uiFiltersParams = {
            ...uiFiltersParams,
            activities: setActivityFilters(activities),
        };
    }
    if (props.org_id) {
        const agencies = isArray(props.org_id) ? props.org_id : [props.org_id];
        uiFiltersParams = {
            ...uiFiltersParams,
            agencies: setAgencyFilters(agencies),
        };
    }
    types.uiFiltersGlossary.forEach((item) => {
        if (props[item.key]) {
            uiFiltersParams = {
                ...uiFiltersParams,
                [item.value]: setGlobalUiFilter(props[item.key], item.filters),
            };
        }
    });
    return uiFiltersParams;
}

// rebuild store state from query params
export function parseQueryParams(urlParams, dispatch, state, useNewCampingFilters) {
    const queryParams = urlParams;
    let search = { ...state.search };
    let changed = false;
    const params = {};

    if (queryParams) {
        if (queryParams.clear === 'true') {
            search = types.SEARCH_INITIAL_STATE;
            params.location = null;
        }
        if (queryParams.use_location === 'false') {
            params.use_location = false;
            params.location = null;
        } else {
            params.use_location = true;
        }
        // handle query text param
        if (
            search.what !== queryParams.q ||
            queryParams.q === 'camping' ||
            queryParams.q === 'venues'
        ) {
            if (queryParams?.q?.toLowerCase() === 'camping') {
                // if someone just searching for camping, convert criteria to filter by camping inventory and remove text
                search.what = '';
                params.what = '';
                queryParams.inventory_type = ['camping'];
            } else if (queryParams?.q?.toLowerCase() === 'venues') {
                search.what = '';
                params.what = '';
                queryParams.inventory_type = ['venuereservations'];
            } else {
                search.what = queryParams.q;
                params.what = queryParams.q;
                params.headerTextQuery = queryParams.q;
            }
        }
        if (search.radius !== queryParams.radius) {
            search.radius = queryParams.radius;
            params.radius = queryParams.radius;
        }
        if (search.lat !== queryParams.lat) {
            search.lat = queryParams.lat;
            params.lat = queryParams.lat;
        }
        if (search.lng !== queryParams.lng) {
            search.lng = queryParams.lng;
            params.lng = queryParams.lng;
        }
        if (search.lat_sw !== queryParams.lat_sw) {
            search.lat_sw = queryParams.lat_sw;
            params.lat_sw = queryParams.lat_sw;
        }
        if (search.lng_sw !== queryParams.lng_sw) {
            search.lng_sw = queryParams.lng_sw;
            params.lng_sw = queryParams.lng_sw;
        }
        if (search.lat_ne !== queryParams.lat_ne) {
            search.lat_ne = queryParams.lat_ne;
            params.lat_ne = queryParams.lat_ne;
        }
        if (search.lng_ne !== queryParams.lng_ne) {
            search.lng_ne = queryParams.lng_ne;
            params.lng_ne = queryParams.lng_ne;
        }
        let uiFilters = {};
        if (queryParams.inventory_type) {
            search.inventory_type = queryParams.inventory_type;
            params.inventory_type = queryParams.inventory_type;
            const invTypes = isArray(params.inventory_type)
                ? params.inventory_type
                : [params.inventory_type];
            uiFilters = {
                ...uiFilters,
                inventoryTypes: setInventoryTypeFilters(invTypes),
            };
        }
        if (queryParams.parent_asset_id) {
            search.parent_asset_id = queryParams.parent_asset_id;
            params.parent_asset_id = queryParams.parent_asset_id;
        } else {
            search.parent_asset_id = null;
            params.parent_asset_id = null;
        }
        if (queryParams.org_id) {
            search.org_id = queryParams.org_id;
            params.org_id = queryParams.org_id;
        } else {
            search.org_id = null;
            params.org_id = null;
        }
        if (search.start !== queryParams.start) {
            let start = null;
            if (queryParams.start) {
                start = Number(queryParams.start);
            }
            if (start === 0) {
                start = null;
            }
            search.start = start;
            params.start = start;
        }
        if (search.size !== queryParams.size) {
            let size = null;
            if (queryParams.size) {
                size = Number(queryParams.size);
            }
            if (size === 0) {
                size = null;
            }
            search.size = size;
            params.size = size;
        }
        if (search.entity_id !== queryParams.entity_id) {
            search.entity_id = queryParams.entity_id;
            params.entity_id = queryParams.entity_id;
        }
        if (search.entity_type !== queryParams.entity_type) {
            search.entity_type = queryParams.entity_type;
            params.entity_type = queryParams.entity_type;
        }
        if (search.sort !== queryParams.sort) {
            search.sort = queryParams.score;
            params.sort = queryParams.sort;
        }
        if (search.checkin_time !== queryParams.checkin) {
            search.checkin_time = queryParams.checkin;
            params.checkin_time = queryParams.checkin;
        }
        if (search.checkout_time !== queryParams.checkout) {
            search.checkout_time = queryParams.checkout;
            params.checkout_time = queryParams.checkout;
        }
        if (queryParams.utm_source) {
            params.utm_source = queryParams.utm_source;
        }
        if (queryParams.utm_medium) {
            params.utm_medium = queryParams.utm_medium;
        }
        if (queryParams.utm_campaign) {
            params.utm_campaign = queryParams.utm_campaign;
        }
        if (queryParams.utm_content) {
            params.utm_content = queryParams.utm_content;
        }
        if (queryParams.avail_months) {
            let availMonths = isArray(queryParams.avail_months)
                ? queryParams.avail_months
                : [queryParams.avail_months];
            availMonths = availMonths.filter((item) => {
                if (item !== 'null' && !!item) {
                    return true;
                }

                return false;
            });
            search.avail_months = availMonths;
            params.avail_months = availMonths;
        }
        if (queryParams.avail_nights) {
            search.avail_nights = queryParams.avail_nights;
            params.avail_nights = queryParams.avail_nights;
        }
        if (queryParams.flex_start_range) {
            params.flex_start_range = queryParams.flex_start_range;
        }
        if (queryParams.flex_end_range) {
            params.flex_end_range = queryParams.flex_end_range;
        }
        params.uiFilters = { ...search.uiFilters };
        if (!isEmpty(uiFilters)) {
            params.uiFilters = { ...search.uiFilters, ...uiFilters };
        }

        const generalUiFiltersParsed = parseUiFiltersFromQuery(
            queryParams,
            params.uiFilters
        );
        if (!isEmpty(generalUiFiltersParsed)) {
            params.uiFilters = { ...params.uiFilters, ...generalUiFiltersParsed };
        }

        if (useNewCampingFilters) {
            params.fg = parseCampingFilters(params, queryParams);
            params.uiFilters.campsiteUseTypes = {};
            params.uiFilters.campsiteTypes = {};
            params.uiFilters.campsiteAmenities = {};
            params.uiFilters.campsiteElectricityHookup = {};
            params.uiFilters.campsiteVehicleLength = {};
            params.uiFilters.campsiteEquipment = {};
            params.uiFilters.occupants = {};
            params.uiFilters.inventoryTypes = {};
            params.inventory_type = [];
            params.include_unavailable = true;
            params.include_notreservable = true;
            params.include_partially_available = true;

            let shouldShowOnlyAvailable = true; // default
            if (
                !isEmpty(queryParams.only_show_available) &&
                queryParams.only_show_available.toLowerCase() === 'false'
            ) {
                shouldShowOnlyAvailable = false;
            }
            const checkinDatesSet =
                !isEmpty(search.checkin_time) && !isEmpty(search.checkout_time);
            if (checkinDatesSet && shouldShowOnlyAvailable) {
                params.include_unavailable = false;
                params.include_notreservable = false;
                params.include_partially_available = false;
            }
        }
    }
    if (!isEmpty(params)) {
        changed = true;
    }
    return { filters: params, changed };
}

export const setEquipmentsInventoryCounts = (data) => ({
    type: types.SET_EQUIPMENTS_INVENTORY_COUNTS,
    data,
});

export const setSiteTypesInventoryCounts = (data) => ({
    type: types.SET_SITE_TYPES_INVENTORY_COUNTS,
    data,
});

export const fetchInventoryCounts = async (dispatch, campgroundId) => {
    const url = `${process.env.API}/search/campsites?agg=type&agg=campsite_equipment_name&size=0&fq=asset_id:${campgroundId}`;

    try {
        const response = await axios.get(url);

        const equipments = get(response, 'data.aggregations.campsite_equipment_name', []);
        const siteTypes = get(response, 'data.aggregations.type', []);

        dispatch(setEquipmentsInventoryCounts(equipments));
        dispatch(setSiteTypesInventoryCounts(siteTypes));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
    }
};

// fetch geo/map specific search results using current search criteria state
export const fetchExactItem = (
    entityId,
    entityType,
    checkinTime,
    checkoutTime,
    search
) => {
    return (dispatch) => {
        let startDate = null;
        let endDate = null;
        if (checkinTime && checkoutTime) {
            startDate = searchDate(checkinTime)
                .utc()
                .startOf('day')
                .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
            endDate = searchDate(checkoutTime)
                .utc()
                .startOf('day')
                .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
        }
        const params = {
            entity_id: entityId,
            entity_type: entityType,
            start_date: startDate,
            end_date: endDate,
        };
        if (search.avail_nights) {
            params.avail_nights = search.avail_nights;
        }
        if (search.avail_months) {
            params.avail_months = search.avail_months;
        }
        if (search.flex_start_range) {
            params.flex_start_range = search.flex_start_range;
        }
        if (search.flex_end_range) {
            params.flex_end_range = search.flex_end_range;
        }
        const url = utils.urlWithParams(`${process.env.API}/search`, params);
        return axios
            .get(url)
            .then((result) => {
                if (result?.data?.results?.length > 0) {
                    dispatch({
                        type: types.GET_EXACT_SEARCH_RESULT,
                        payload: { exactResult: result?.data?.results[0] },
                    });
                }
            })
            .catch(() => {});
    };
};

// Fetch boundary data using the name of the state / city / facility
export const fetchBoundary = (boundaryType, boundaryName) => {
    return (dispatch) => {
        const params = {
            fq: [`boundary_type:${boundaryType}`],
            q: boundaryName?.toLowerCase(),
        };

        if (!boundaryType || !boundaryName) {
            return dispatch({
                type: types.GET_BOUNDARY_SEARCH_RESULT,
                payload: { boundaryResults: [] },
            });
        }
        const url = utils.urlWithParams(`${process.env.API}/search/boundaries`, params);
        return axios
            .get(url)
            .then((result) => {
                dispatch({
                    type: types.GET_BOUNDARY_SEARCH_RESULT,
                    payload: {
                        boundaryResults: result.data.results,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.GET_BOUNDARY_SEARCH_RESULT_ERROR,
                    payload: { boundaryResults: [] },
                });
                dispatch(searchFailed(`Error fetching boundary data: ${error}`));
            });
    };
};

// Fetch trail data using the name of the state / city / facility
export const fetchTrails = (boundingBox) => {
    return (dispatch) => {
        const { latSw, lngSw, latNe, lngNe } = boundingBox;
        const params = {
            lat_sw: latSw,
            lng_sw: lngSw,
            lat_ne: latNe,
            lng_ne: lngNe,
            size: 500,
        };

        if (!latSw || !lngSw || !latNe || !lngNe) {
            return dispatch({
                type: types.GET_TRAILS_SEARCH_RESULT,
                payload: { trailResults: [] },
            });
        }

        const url = utils.urlWithParams(`${process.env.API}/search/trails`, params);

        return axios
            .get(url)
            .then((result) => {
                dispatch({
                    type: types.GET_TRAILS_SEARCH_RESULT,
                    payload: {
                        trailResults: result.data.results,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.GET_TRAILS_SEARCH_RESULT_ERROR,
                    payload: { trailResults: [] },
                });
                dispatch(searchFailed(`Error fetching trails data: ${error}`));
            });
    };
};

// Fetch ev using the map view bounding box
export const fetchEV = (boundingBox) => {
    return (dispatch) => {
        const { latSw, lngSw, latNe, lngNe } = boundingBox;
        const params = {
            lat_sw: latSw,
            lng_sw: lngSw,
            lat_ne: latNe,
            lng_ne: lngNe,
            size: 500,
        };

        if (!latSw || !lngSw || !latNe || !lngNe) {
            return dispatch({
                type: types.GET_EV_SEARCH_RESULT,
                payload: { evResults: [] },
            });
        }

        const url = utils.urlWithParams(`${process.env.API}/search/ev`, params);

        return axios
            .get(url)
            .then((result) => {
                dispatch({
                    type: types.GET_EV_SEARCH_RESULT,
                    payload: {
                        evResults: result.data.results,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.GET_EV_SEARCH_RESULT_ERROR,
                    payload: { evResults: [] },
                });
                dispatch(searchFailed(`Error fetching ev data: ${error}`));
            });
    };
};

export function submitSimpleSearch(
    optionalQuery,
    optionalInventoryTypes,
    optionalSuggestion,
    optionalParentAssetId,
    optionalUIFilters,
    isAcrossUS = false,
    optionalBounds = null,
    optionalSort = null,
    optionalDates,
    omitUnavailable,
    optionalFg
) {
    return (dispatch) => {
        // reset existing filters/search state
        dispatch(resetSearchFilters());

        if (SessionStorage) {
            SessionStorage.removeItem('scrollManagedPaths');
            SessionStorage.removeItem('focusManagedPaths');
        }

        let lat;
        let lng;
        let radius;
        let location;
        let lat_sw;
        let lng_sw;
        let lat_ne;
        let lng_ne;
        if (
            optionalSuggestion &&
            !optionalSuggestion.entity_type &&
            optionalSuggestion.lat &&
            optionalSuggestion.lng
        ) {
            // default search to suggestion location
            lat = optionalSuggestion.lat;
            lng = optionalSuggestion.lng;
            radius = 200;
            location = optionalSuggestion.text;

            // apply only to large states
            if (
                optionalSuggestion.lat_sw &&
                optionalSuggestion.lat_ne &&
                optionalSuggestion.lng_sw &&
                optionalSuggestion.lng_ne
            ) {
                lat = null;
                lng = null;
                lat_sw = optionalSuggestion.lat_sw;
                lng_sw = optionalSuggestion.lng_sw;
                lat_ne = optionalSuggestion.lat_ne;
                lng_ne = optionalSuggestion.lng_ne;
            }
        }

        const criteria = {
            what: optionalQuery,
            headerTextQuery: optionalQuery,
            entity_id: optionalSuggestion != null ? optionalSuggestion.entity_id : null,
            entity_type:
                optionalSuggestion != null ? optionalSuggestion.entity_type : null,
            inventory_type: optionalInventoryTypes,
            parent_asset_id: optionalParentAssetId,
            highlighted_item: -1,
            exact: false,
            lat,
            lng,
            radius,
            map_center_lat: lat,
            map_center_lng: lng,
            start: null,
            cursor_mark: null,
            location,
            placename: null,
            lat_sw,
            lng_sw,
            lat_ne,
            lng_ne,
        };

        if (optionalFg) {
            criteria.fg = optionalFg;
        }

        if (omitUnavailable) {
            criteria.include_partially_available = false;
            criteria.include_notreservable = false;
            criteria.include_unavailable = false;
        }

        // append any custom search filters
        if (optionalUIFilters) {
            Object.keys(optionalUIFilters).forEach((key) => {
                Object.keys(optionalUIFilters[key]).forEach((fltKey) => {
                    dispatch(addSearchFilter(key, optionalUIFilters[key][fltKey]));
                });
            });
        }

        if (isAcrossUS) {
            // for tours only, set bounding box for search to entire US and sort results by
            // distance/proximity to user's location
            criteria.lng_sw = -167.2815442995964;
            criteria.lat_sw = 3.6722362889127;
            criteria.lng_ne = -51.637969429829155;
            criteria.lat_ne = 60.77726184625004;
            criteria.sort = 'distance';
        }

        if (optionalBounds) {
            criteria.lat_sw = optionalBounds?.lat_sw;
            criteria.lng_sw = optionalBounds?.lng_sw;
            criteria.lat_ne = optionalBounds?.lat_ne;
            criteria.lng_ne = optionalBounds?.lng_ne;
        }

        if (optionalSort) {
            criteria.sort = optionalSort;
        }

        if (optionalDates?.startDate && optionalDates?.endDate) {
            criteria.checkin_time = searchDate(optionalDates?.startDate)
                .startOf('day')
                .format(types.CAL_DATE_FORMAT);
            criteria.checkout_time = searchDate(optionalDates?.endDate)
                .startOf('day')
                .format(types.CAL_DATE_FORMAT);
        }

        dispatch(updateSearchCriterias(criteria));
        dispatch(navigateToSearchResults());
    };
}

// Fetch inventory locations
export async function fetchNearbyInventoryLocations(
    lat = null,
    lng = null,
    inventoryTypes = [],
    size = 20,
    isAcrossUS = false,
    sort = 'score',
    additionalFilters = [],
    start = 0,
    aggregations = [],
    locationName = '',
    optionalBounds = null,
    startDate,
    endDate,
    omitUnavailable,
    radius,
    locationId
) {
    const filters = [];

    inventoryTypes.forEach((name) => {
        const inventoryTypeFilter = find(inventoryTypeFilters, { key: name });
        inventoryTypeFilter.filters.forEach((filter) => {
            const parts = filter.filterValue.split(',');
            parts.forEach((value) => {
                filters.push(`${filter.attribute}:${value}`);
            });
        });
    });

    filters.push(...additionalFilters);
    const params = {
        fq: filters,
        sort,
        start,
        size,
        agg: aggregations,
        exact: false,
    };
    if (lat && lng) {
        params.lat = lat;
        params.lng = lng;
        params.location = locationName;
    }

    if (radius) {
        params.radius = radius;
    }

    if (startDate && endDate) {
        params.start_date = startDate;
        params.end_date = endDate;
    }

    if (omitUnavailable) {
        params.include_partially_available = false;
        params.include_notreservable = false;
        params.include_unavailable = false;
    }

    if (optionalBounds) {
        params.lat_sw = optionalBounds?.lat_sw;
        params.lng_sw = optionalBounds?.lng_sw;
        params.lat_ne = optionalBounds?.lat_ne;
        params.lng_ne = optionalBounds?.lng_ne;
    }

    if (isAcrossUS) {
        params.lng_sw = -167.2815442995964;
        params.lat_sw = 3.6722362889127;
        params.lng_ne = -51.637969429829155;
        params.lat_ne = 60.77726184625004;
    }
    if (locationId) {
        params.location_ids = locationId;
    }

    return axios.get(utils.urlWithParams(`${process.env.API}/search`, params));
}

// Fetch Recommendation Suggestions
export async function fetchRecommendationSuggestions(
    inventoryTypes = [],
    size = 20,
    startDate,
    endDate,
    radius,
    locationId,
    sort
) {
    const filters = [];

    inventoryTypes.forEach((name) => {
        const inventoryTypeFilter = find(inventoryTypeFilters, { key: name });
        inventoryTypeFilter.filters.forEach((filter) => {
            const parts = filter.filterValue.split(',');
            parts.forEach((value) => {
                if (value !== 'campground' && name !== 'camping') {
                    filters.push(`${filter.attribute}:${value}`);
                }
            });
        });
    });

    const hasCamping = inventoryTypes.some((name) => name === 'camping');
    if (hasCamping) {
        filters.push('entity_type:campgroung');
    }

    const params = {
        fq: [...filters],
        size,
        exact: false,
    };

    if (radius) {
        params.radius = radius;
    }

    if (startDate && endDate) {
        params.start_date = startDate;
        params.end_date = endDate;
    }

    if (locationId) {
        params.location_ids = locationId;
    }

    if (sort) {
        params.sort = sort;
    }

    return axios.get(
        utils.urlWithParams(`${process.env.API}/recommendation/suggest`, params)
    );
}

export function submitSearchFromNavigation(
    optionalQuery,
    optionalSuggestion,
    optionalUIFilters
) {
    return (dispatch, getState) => {
        const state = cloneState(getState());
        // reset existing filters/search state
        dispatch(resetSearchFilters());

        if (SessionStorage) {
            SessionStorage.removeItem('scrollManagedPaths');
            SessionStorage.removeItem('focusManagedPaths');
        }

        let lat;
        let lng;
        let radius;
        let location;
        let lat_sw;
        let lng_sw;
        let lat_ne;
        let lng_ne;
        if (
            optionalSuggestion &&
            !optionalSuggestion.entity_type &&
            optionalSuggestion.lat &&
            optionalSuggestion.lng
        ) {
            // default search to suggestion location
            lat = optionalSuggestion.lat;
            lng = optionalSuggestion.lng;
            radius = 200;
            location = optionalSuggestion.text;

            // apply only to large states
            if (
                optionalSuggestion.lat_sw &&
                optionalSuggestion.lat_ne &&
                optionalSuggestion.lng_sw &&
                optionalSuggestion.lng_ne
            ) {
                lat = null;
                lng = null;
                lat_sw = optionalSuggestion.lat_sw;
                lng_sw = optionalSuggestion.lng_sw;
                lat_ne = optionalSuggestion.lat_ne;
                lng_ne = optionalSuggestion.lng_ne;
            }
        }

        const criteria = {
            what: optionalQuery,
            headerTextQuery: optionalQuery,
            entity_id: optionalSuggestion != null ? optionalSuggestion.entity_id : null,
            entity_type:
                optionalSuggestion != null ? optionalSuggestion.entity_type : null,
            highlighted_item: -1,
            exact: false,
            lat,
            lng,
            radius,
            map_center_lat: lat,
            map_center_lng: lng,
            start: null,
            cursor_mark: null,
            location,
            placename: null,
            lat_sw,
            lng_sw,
            lat_ne,
            lng_ne,
        };

        // append any custom search filters
        if (optionalUIFilters) {
            Object.keys(optionalUIFilters).forEach((key) => {
                Object.keys(optionalUIFilters[key]).forEach((fltKey) => {
                    dispatch(addSearchFilter(key, optionalUIFilters[key][fltKey]));
                });
            });
        }

        let nearByLocation = false;
        if (optionalQuery) {
            const q = optionalQuery.toLowerCase();
            nearByDictionary.forEach((word) => {
                if (q.indexOf(word) >= 0) {
                    nearByLocation = true;
                }
            });
        }

        let geocodeUserLocation = false;

        if (
            isBlank(state.search.lat) &&
            isBlank(state.search.lng) &&
            (isBlank(optionalQuery) || nearByLocation)
        ) {
            if (
                state.search.hasOwnProperty('use_location') &&
                !state.search.use_location
            ) {
                criteria.lat = null;
                criteria.lng = null;
                criteria.radius = null;
                criteria.location = null;
            } else {
                geocodeUserLocation = true;
            }
        }

        if (sessionStorage && geocodeUserLocation && !criteria.lat && !criteria.lng) {
            let position = sessionStorage.getItem('browserPosition');
            if (position) {
                position = JSON.parse(position);
                if (position.location && position.location.city) {
                    location = `${position.location.city}, ${position.location.region_name}`;
                } else {
                    location = null;
                }
                criteria.lat = position.latitude;
                criteria.lng = position.longitude;
                criteria.radius = null;
                criteria.location = location;
            }
        }

        dispatch(updateSearchCriterias(criteria));

        setTimeout(() => {
            const uri = buildSearchNavUri('/search', getState());
            window.location?.assign(uri);
        }, 100);
    };
}

// cSpell:ignore Criterias aggs checkin geoip notreservable giftcard placeid recarea activitypass ticketfacility tickettours timedentry dayuse nearme treepermit vehiclepermit venuereservations
