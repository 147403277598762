/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { RadioToggle, Button, Icon } from 'sarsaparilla';
import cx from 'classnames';
import CarouselTab from './CarouselTab';
import CalendarTab, { CalendarTabProps } from './CalendarTab';
import { MonthsOptionsProps } from './FlexDatesInput';

const flexOptions = [
    { label: 'Choose Dates', value: '0' },
    { label: "I'm Flexible!", value: '1' },
];

type Ref = HTMLDivElement | null;

export interface FlexDatesTabsProps extends CalendarTabProps {
    tab: string;
    isMobile: boolean;
    setTab: React.Dispatch<React.SetStateAction<string>>;
    setError: React.Dispatch<React.SetStateAction<string>>;
    handleApplyFilters: () => void;
    onClear: () => void;
    monthsOptions: Array<MonthsOptionsProps>;
    setChecked: React.Dispatch<React.SetStateAction<Array<string>>>;
    setDuration: React.Dispatch<React.SetStateAction<string>>;
    setStartRange: React.Dispatch<React.SetStateAction<string>>;
    startRange: string;
    setEndRange: React.Dispatch<React.SetStateAction<string>>;
    endRange: string;
    checked: Array<string>;
    duration: string;
    onClose: () => void;
    limit?: number;
    generateGaProps: () => {
        clickTagCategory: string;
        clickTagAction: string;
        clickTagLabel: string;
    };
}

const FlexDatesTabs = React.forwardRef<Ref, FlexDatesTabsProps>(
    (
        {
            handleApplyFilters,
            filters,
            tab,
            setTab,
            monthsOptions,
            isMobile,
            setError,
            dates,
            setDates,
            error,
            setDuration,
            onClear,
            duration,
            checked,
            setChecked,
            onClose,
            limit,
            setStartRange,
            startRange,
            setEndRange,
            endRange,
            generateGaProps,
        },
        ref
    ) => {
        // Find the location of the page for GA
        const isSearchPage = window.location.pathname.includes('search');
        return (
            <div ref={ref} className={cx('flex-dates-portal', { desktop: !isMobile })}>
                <div className="flex-dates-tabs">
                    <div id="flex-radio-selector">
                        <RadioToggle
                            options={flexOptions}
                            value={tab}
                            clickTagCategory={isSearchPage ? 'Search' : 'Homepage'}
                            clickTagLabel="Flexible Booking"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setError('');
                                setTab(e.target.value);
                            }}
                        />
                        {!isMobile && (
                            <div className="sarsa-modal-content">
                                <Button
                                    id="flex-dates-close-button"
                                    appearance="subtle"
                                    className="flex-dates-close-button sarsa-modal-close-button"
                                    onClick={onClose}
                                    screenReaderTextBefore="close"
                                    iconAfterElement={<Icon iconName="close" />}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    {tab === '0' && (
                        <CalendarTab
                            onApplyFilters={handleApplyFilters}
                            filters={filters}
                            isMobile={isMobile}
                            setError={setError}
                            dates={dates}
                            setDates={setDates}
                            error={error}
                            onClear={onClear}
                            startRange={startRange}
                            setStartRange={setStartRange}
                            setEndRange={setEndRange}
                            endRange={endRange}
                            generateGaProps={generateGaProps}
                        />
                    )}
                    {tab === '1' && (
                        <CarouselTab
                            onApplyFilters={handleApplyFilters}
                            filters={filters}
                            monthsOptions={monthsOptions}
                            setError={setError}
                            isMobile={isMobile}
                            error={error}
                            onClear={onClear}
                            duration={duration}
                            checked={checked}
                            setChecked={setChecked}
                            setDuration={setDuration}
                            limit={limit}
                        />
                    )}
                </div>
            </div>
        );
    }
);

export default FlexDatesTabs;

// cspell:ignore sarsa
