/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import axios from 'axios';
import toNumber from 'lodash/toNumber';
import { URLHelper, SessionStorage } from 'sarsaparilla';

import {
    defaultSearchLocation,
    maximumMapSearchResults,
    entityTypes,
} from '../constants/mapValues';

import {
    CLEAR_MAP_SUGGESTIONS,
    FETCHING_MAP_INVENTORY_FAILED,
    MAP_INVENTORY_FETCHED,
    UPDATE_MAP_SEARCH_TERM,
    UPDATE_MAP_SUGGESTIONS,
    UPDATE_MAP_SEARCH_RESULT_LAT_LONG,
    INVENTORY_SELECTED_CARD,
    INVENTORY_DESELECTED_CARD,
    REMOVE_FILTER,
    ADD_FILTER,
    REMOVE_FILTERS,
    ADD_FILTERS,
    UPDATE_FILTERED_MAP_INVENTORY,
    UPDATE_MAP_SEARCH_RESULT,
} from '../constants/actionTypes';

const updateSuggestions = (suggestions) => ({
    type: UPDATE_MAP_SUGGESTIONS,
    suggestions,
});

export const clearSuggestions = () => ({
    type: CLEAR_MAP_SUGGESTIONS,
});

export const updateSelectedMapSearchTerm = (selectedMapSearchTerm) => ({
    type: UPDATE_MAP_SEARCH_TERM,
    selectedMapSearchTerm,
});

export const updateMapSearchResultLocation = (mapSearchResultLocation) => ({
    type: UPDATE_MAP_SEARCH_RESULT_LAT_LONG,
    mapSearchResultLocation,
});

export const updateMapSearchResult = (mapSearchResult) => ({
    type: UPDATE_MAP_SEARCH_RESULT,
    mapSearchResult,
});

const mapInventoryFetched = (mapInventory) => ({
    type: MAP_INVENTORY_FETCHED,
    mapInventory,
});

const fetchingMapInventoryFailed = () => ({
    type: FETCHING_MAP_INVENTORY_FAILED,
});

export const getCurrentUserLocation = () => {
    return async (dispatch) => {
        // if position is in cache, then return it
        if (SessionStorage) {
            let browserPosition = SessionStorage.getItem('browserPosition');
            if (browserPosition) {
                browserPosition = JSON.parse(browserPosition);
                dispatch(
                    updateMapSearchResultLocation({
                        latitude: browserPosition.latitude,
                        longitude: browserPosition.longitude,
                    })
                );
                return;
            }
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // on success
                    // saved fetched position in cache
                    if (SessionStorage) {
                        try {
                            SessionStorage.setItem(
                                'browserPosition',
                                JSON.stringify({
                                    latitude: position.coords.latitude,
                                    longitude: position.coords.longitude,
                                })
                            );
                        } catch (e) {
                            // Absorbing error
                            // eslint-disable-next-line no-console
                            console.warn(e);
                        }
                    }
                    dispatch(
                        updateMapSearchResultLocation({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        })
                    );
                },
                () => {
                    // on error
                    dispatch(updateMapSearchResultLocation(defaultSearchLocation));
                },
                {
                    // optimization params
                    enableHighAccuracy: false,
                    maximumAge: Infinity,
                }
            );
        } else {
            dispatch(updateMapSearchResultLocation(defaultSearchLocation));
        }
    };
};

export const fetchMapInventory = (geoSearchArgs) => {
    return async (dispatch) => {
        const args = {
            ...geoSearchArgs,
            size: maximumMapSearchResults,
        };

        const url = URLHelper.urlWithParams(`${process.env.API}/search/geo`, args);

        try {
            const response = await axios.get(url);

            dispatch(mapInventoryFetched(response.data.results));
        } catch (error) {
            dispatch(fetchingMapInventoryFailed());
        }
    };
};

export const performMapSearch = (search) => {
    return async (dispatch) => {
        try {
            const q = encodeURIComponent(search);

            const url = `${process.env.API}/search/suggest?q=${q}&geocoder=true`;
            const response = await axios.get(url);

            dispatch(updateSuggestions(response.data.suggestions));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    };
};

export const selectMapSearchResult = (selection, suggestion) => {
    return async (dispatch) => {
        dispatch(updateSelectedMapSearchTerm(selection));
        dispatch(clearSuggestions());
        if (suggestion) {
            dispatch(updateMapSearchResult(suggestion));
            dispatch(
                updateMapSearchResultLocation({
                    latitude: toNumber(suggestion.lat),
                    longitude: toNumber(suggestion.lng),
                })
            );
        }
    };
};

export const selectItem = (item) => ({
    type: INVENTORY_SELECTED_CARD,
    selectedCardItem: item,
});

export const deselectItem = () => ({
    type: INVENTORY_DESELECTED_CARD,
});

export const removeFilter = (item) => {
    return {
        type: REMOVE_FILTER,
        item,
    };
};

export const addFilter = (item) => {
    return {
        type: ADD_FILTER,
        item,
    };
};

export const removeFilters = () => {
    return {
        type: REMOVE_FILTERS,
    };
};

export const addFilters = () => {
    return {
        type: ADD_FILTERS,
    };
};

export const updateFilteredMapInventory = (filteredMapInventory) => {
    return (dispatch) => {
        return dispatch({
            type: UPDATE_FILTERED_MAP_INVENTORY,
            filteredMapInventory,
        });
    };
};

export const filterMapInventory = () => {
    return async (dispatch, getState) => {
        const mapInventory = getState().navigation.map.mapInventory;
        const filters = getState().navigation.map.uiFilters;

        const filteredInventory = mapInventory.filter((item) => {
            if (item.entity_type === 'campground') {
                const entityType = item.campsite_type_of_use[0].toLowerCase();
                return !!filters[entityTypes[entityType]];
            }
            return !!filters[entityTypes[item.entity_type]];
        });

        dispatch(updateFilteredMapInventory(filteredInventory));
    };
};

export const toggleFilter = (item) => {
    return async (dispatch, getState) => {
        const currentFilters = getState().navigation.map.uiFilters;
        const filterExists = !!currentFilters[item.key];
        if (filterExists) {
            dispatch(removeFilter(item));
        } else {
            dispatch(addFilter(item));
        }
    };
};
